<template>
  <button
    v-if="!to"
    :disabled="disabled"
    @click="handleClick"
  >
    <slot />
  </button>
  <router-link
    v-else-if=renderRouterLink
    :to="to"
    @click="handleClick"
  >
    <slot />
  </router-link>
  <a
    v-else
    target="_blank"
    rel="noopener"
    :href="to"
    @click="handleClick"
  >
    <slot />
  </a>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['click']);
const props = defineProps({
  to: {
    type: [String, Object],
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const renderRouterLink = computed(() => (
  typeof props.to === 'object' || props.to.startsWith('#')
));

function handleClick(event) {
  /**
   * Prevents <a> and <router-link> elements from being activated
   * with Tab + Enter keys when button is disabled
   */
  if (props.disabled) {
    event.preventDefault();
    return;
  }

  emit('click');
}
</script>
