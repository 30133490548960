import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const initializeAppInsights = () => {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.VUE_APP_AZURE_INSIGHTS_CONNECTION_STRING,
    },
  });

  appInsights.loadAppInsights();

  appInsights.trackPageView();

  return appInsights;
};
const appInsights = initializeAppInsights();

export default {
  namespaced: true,
  state() {
    return {
      lastTrackedNavigationEvent: null,
    };
  },
  getters: {
    lastTrackedNavigationEvent: (state) => state.lastTrackedNavigationEvent,
  },
  mutations: {
    setLastTrackedNavigationEvent(state, event) {
      state.lastTrackedNavigationEvent = event;
    },
  },
  actions: {
    trackEvent(_, { name }) {
      if (!name) return;
      appInsights.trackEvent({ name });
    },
    trackSectionNavigation({ getters, commit, dispatch }, event) {
      const THRESHOLD_PIXELS = 1;
      const sections = document.querySelectorAll('section[data-track-event], footer[data-track-event]');
      const headerHeight = document.querySelector('header#header').offsetHeight;
      const {
        scrollTop,
        clientHeight: targetClientHeight,
        scrollHeight: targetScrollHeight,
      } = event.target.documentElement;

      let eventName = getters.lastTrackedNavigationEvent;

      // If scroll arrived at the bottom of the page, get last section event
      if (scrollTop + targetClientHeight >= targetScrollHeight - THRESHOLD_PIXELS) {
        eventName = sections[sections.length - 1].getAttribute('data-track-event');
      } else {
        sections.forEach((section) => {
          const sectionHeight = section.offsetHeight;
          const distanceFromTop = section.offsetTop - headerHeight;

          if (scrollTop > distanceFromTop && scrollTop <= distanceFromTop + sectionHeight) {
            eventName = section.getAttribute('data-track-event');
          }
        });
      }

      if (eventName !== getters.lastTrackedNavigationEvent) {
        commit('setLastTrackedNavigationEvent', eventName);

        dispatch('trackEvent', {
          name: eventName,
        });
      }
    },
  },
};
