import { baseCRUDAPI } from './base';

export default class SweepstakesService extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'sweepstakes');
  }

  getVehiclePurchaseLeaseOptions() {
    return this.axiosObject.get('/purchase-or-lease');
  }

  getVehicleInterestsOptions() {
    return this.axiosObject.get('/vehicle');
  }

  getVehiclesInHouseholdOptions() {
    return this.axiosObject.get('/currently-owned-vehicles');
  }

  getAcquireNextVehicleOptions() {
    return this.axiosObject.get('/vehicle-acquire-next');
  }

  getVehicleBrandOptions() {
    return this.axiosObject.get('/vehicle-brand');
  }

  getElectricVehiclesFamiliarityOptions() {
    return this.axiosObject.get('/vehicle-electric-familiarity');
  }

  submitForm(data) {
    return this.axiosObject.post(`${this.apiBaseName}`, {
      campaignId: data.campaignId || '',
      eventName: data.eventName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zipCode,
      phone: data.phoneNumber,
      ofAge: true,
      rules: true,
      currentlyOwnedVehiclesId: data.currentlyOwnedVehicles,
      vehicleCurrentlyDriveElectric: data.drivesElectricVehicle,
      vehicleElectricImpression: data.electricVehiclesEvaluation || null,
      fordMotorCompanyOpinion: data.fordMotorCompanyOpinion,
      fordMotorCommunication: data.receiveCommunicationEmails,
      onSite: data.onSite,
      vehicle1Id: data.vehicle1 || null,
      vehicle2Id: data.vehicle2 || null,
      vehicle3Id: data.vehicle3 || null,
      vehicleBrandId: data.ownedVehicleBrand || null,
      vehicleAcquireNextId: data.acquireNextVehicleIn || null,
    });
  }

  getRegistrationWithApiKey({ emailOrPhoneNumber, apiKey }) {
    return this.axiosObject.get(`${this.apiBaseName}/email-or-phone`, {
      params: { emailOrPhone: emailOrPhoneNumber },
      headers: { 'X-API-Key': apiKey },
    });
  }
}
