import { baseCRUDAPI } from './base';

export default class CommunicationPreferencesService extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'communication-preference');
  }

  unsubscribe({ id, unsubscribe }) {
    return this.axiosObject.post(`${this.apiBaseName}/unsubscribe/${id}`, {
      unsubscribe,
    });
  }
}
