import { baseCRUDAPI } from './base';

export default class SweepstakesService extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, '');
  }

  saveTestDriveRegistration(data, sendThankYouEmail = false) {
    return this.axiosObject.post(`/test-drive-registration?sendThankYouEmail=${sendThankYouEmail}`, {
      eventName: data.eventName,
      eventDate: data.eventDate,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zipCode,
      phone: data.phoneNumber,
      vehicleCurrentlyDriveElectric: data.drivesElectricVehicle,
      fordMotorCompanyOpinion: data.fordMotorCompanyOpinion || null,
      vehicleElectricImpression: data.electricVehiclesEvaluation || null,
      fordMotorCommunication: data.receiveCommunicationEmails,
      vehicle1Id: data.vehicle1 || null,
      vehicle2Id: data.vehicle2 || null,
      vehicle3Id: data.vehicle3 || null,
      currentlyOwnedVehiclesId: data.currentlyOwnedVehicles || null,
      vehicleBrandId: data.ownedVehicleBrand || null,
      vehicleAcquireNextId: data.acquireNextVehicleIn || null,
      pdfData: data.pdfData,
      driverConsent: data.agreeToTestDriveWaiver,
      minorConsent: data.agreeToMinorWaiver,
      testDriveMinors: data.testDriveMinors,
      createSweepstakes: data.signUpToSweepstakes,
    });
  }

  saveTestDriveSurvey(data) {
    return this.axiosObject.post('/post-test-drive-survey', {
      email: data.email,
      phone: data.phoneNumber,
      zip: data.zipCode,
      range: data.selectedChoices.includes('range'),
      stylingDesign: data.selectedChoices.includes('stylingDesign'),
      technology: data.selectedChoices.includes('technology'),
      safety: data.selectedChoices.includes('safety'),
      funToDrive: data.selectedChoices.includes('funToDrive'),
      costOfOwnership: data.selectedChoices.includes('costOfOwnership'),
      price: data.selectedChoices.includes('price'),
      reliabilityDependability: data.selectedChoices.includes('reliabilityDependability'),
      performance: data.selectedChoices.includes('performance'),
      environmentallyFriendly: data.selectedChoices.includes('environmentallyFriendly'),
      governmentIncentives: data.selectedChoices.includes('governmentIncentives'),
      vehicleBrandId: data.purchaseLeaseVehicleBrand || null,
      financeChargingMoreLikelyPurchase: data.financeChargingInstallation || null,
      purchaseOrLeaseId: data.purchaseLeaseVehicleLikelihood || null,
      fordBatteryElectricVehiclesImpression: data.electricVehiclesEvaluation || null,
      fordMotorCompanyOpinion: data.fordMotorCompanyOpinion || null,
      fordMotorCommunication: data.receiveCommunicationEmails,
    });
  }
}
