import axios from 'axios';

import AuthService from './auth';
import CommunicationPreferencesService from './communicationPreferences';
import EventsService from './events';
import RaffleService from './raffle';
import SweepstakesService from './sweepstakes';
import TestDriveService from './testDrive';

const success = (response) => response;
const failure = (error) => Promise.reject(error);

class ApiManager {
  constructor() {
    this.requiresAuth = false;
    // Core api for project
    this.coreObject = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_APP_URL,
    });

    this.coreObject.interceptors.response.use(success, failure);

    this.authService = new AuthService(this.coreObject);
    this.communicationPreferencesService = new CommunicationPreferencesService(this.coreObject);
    this.eventsService = new EventsService(this.coreObject);
    this.raffleService = new RaffleService(this.coreObject);
    this.sweepstakesService = new SweepstakesService(this.coreObject);
    this.testDriveService = new TestDriveService(this.coreObject);
  }

  /**
   * Add any APIs that require authentication to the array within method.
   */
  setAuthHeader(authToken) {
    [
      this.eventsService,
      this.sweepstakesService,
      // add API needing authentication here
    ].forEach((api) => api.setAuthHeader(authToken));
  }
}

export default new ApiManager();
