import { baseCRUDAPI } from './base';

export default class RaffleService extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, '');
  }

  messageRaffleWinners({
    winnerMessage,
    reserveMessage,
    responsePhone,
    winnerPhoneNumbers,
    reservePhoneNumbers,
    apiKey,
  }) {
    const phoneNumbers = winnerPhoneNumbers.map((phone) => ({
      phone,
      winner: true,
    }));

    reservePhoneNumbers.forEach((phone) => {
      phoneNumbers.push({
        phone,
        winner: false,
      });
    });

    return this.axiosObject.post('/raffle-message', {
      winnerMessage,
      reserveMessage,
      responsePhone,
      raffleMessagePhones: phoneNumbers,
    }, {
      headers: { 'X-API-Key': apiKey },
    });
  }

  sendPhoto({
    message,
    phoneNumberList,
    base64Image,
    apiKey,
  }) {
    return this.axiosObject.post('/raffle-message/raffle-image', {
      message,
      data: base64Image,
      raffleImagePhones: phoneNumberList.map((phone) => ({ phone })),
    }, {
      headers: { 'X-API-Key': apiKey },
    });
  }
}
