import api from '@/api';

const getDropdownOptions = (options) => (
  options.map(({ id, name }) => ({
    text: name,
    value: id,
  }))
);

export default {
  namespaced: true,
  state() {
    return {
      events: [],
      vehiclePurchaseLeaseOptions: [],
      vehiclesInHouseholdOptions: [],
      vehicleInterestsOptions: [],
      acquireNextVehicleOptions: [],
      vehicleBrandOptions: [],
      electricVehiclesFamiliarityOptions: [],
    };
  },
  getters: {
    events: (state) => state.events,
    eventOptions: (state) => (state.events
      .filter(({ fieldData }) => fieldData.isActiveEvent)
      .map(({ fieldData }) => ({
        text: fieldData.eventName,
        value: fieldData.eventName,
      }))
    ),
    vehiclePurchaseLeaseOptions: (state) => getDropdownOptions(state.vehiclePurchaseLeaseOptions),
    vehiclesInHouseholdOptions: (state) => getDropdownOptions(state.vehiclesInHouseholdOptions),
    vehicleInterestsOptions: (state) => getDropdownOptions(state.vehicleInterestsOptions),
    acquireNextVehicleOptions: (state) => getDropdownOptions(state.acquireNextVehicleOptions),
    vehicleBrandOptions: (state) => getDropdownOptions(state.vehicleBrandOptions),
    electricVehiclesFamiliarityOptions: (state) => (
      getDropdownOptions(state.electricVehiclesFamiliarityOptions)
    ),
  },
  mutations: {
    setEvents(state, events) {
      state.events = events;
    },
    setVehiclesInHouseholdOptions(state, options) {
      state.vehiclesInHouseholdOptions = options;
    },
    setVehiclePurchaseLeaseOptions(state, options) {
      state.vehiclePurchaseLeaseOptions = options;
    },
    setVehicleInterestsOptions(state, options) {
      state.vehicleInterestsOptions = options;
    },
    setAcquireNextVehicleOptions(state, options) {
      state.acquireNextVehicleOptions = options;
    },
    setVehicleBrandOptions(state, options) {
      state.vehicleBrandOptions = options;
    },
    setElectricVehiclesFamiliarityOptions(state, options) {
      state.electricVehiclesFamiliarityOptions = options;
    },
  },
  actions: {
    async getEvents({ commit }) {
      const {
        data: { events: { response: { data: events } } },
      } = await api.eventsService.getAll();

      commit('setEvents', events);
    },
    async getVehiclePurchaseLeaseOptions({ getters, commit }) {
      if (getters.vehiclePurchaseLeaseOptions.length) return;

      const {
        data: { purchaseOrLease },
      } = await api.sweepstakesService.getVehiclePurchaseLeaseOptions();

      commit('setVehiclePurchaseLeaseOptions', purchaseOrLease);
    },
    async getVehicleInterestsOptions({ getters, commit }) {
      if (getters.vehicleInterestsOptions.length) return;

      const {
        data: { vehicles },
      } = await api.sweepstakesService.getVehicleInterestsOptions();

      commit('setVehicleInterestsOptions', vehicles);
    },
    async getVehiclesInHouseholdOptions({ getters, commit }) {
      if (getters.vehiclesInHouseholdOptions.length) return;

      const {
        data: { currentlyOwnedVehicles },
      } = await api.sweepstakesService.getVehiclesInHouseholdOptions();

      commit('setVehiclesInHouseholdOptions', currentlyOwnedVehicles);
    },
    async getAcquireNextVehicleOptions({ getters, commit }) {
      if (getters.acquireNextVehicleOptions.length) return;

      const {
        data: { vehicleAcquireNexts },
      } = await api.sweepstakesService.getAcquireNextVehicleOptions();

      commit('setAcquireNextVehicleOptions', vehicleAcquireNexts);
    },
    async getVehicleBrandOptions({ getters, commit }) {
      if (getters.vehicleBrandOptions.length) return;

      const {
        data: { vehicleBrands },
      } = await api.sweepstakesService.getVehicleBrandOptions();

      commit('setVehicleBrandOptions', vehicleBrands);
    },
    async getElectricVehiclesFamiliarityOptions({ getters, commit }) {
      if (getters.electricVehiclesFamiliarityOptions.length) return;

      const {
        data: { vehicleElectricFamiliarities },
      } = await api.sweepstakesService.getElectricVehiclesFamiliarityOptions();

      commit('setElectricVehiclesFamiliarityOptions', vehicleElectricFamiliarities);
    },
    async getRegistrationWithApiKey({ rootGetters }, { emailOrPhoneNumber }) {
      const { data } = await api.sweepstakesService.getRegistrationWithApiKey({
        emailOrPhoneNumber,
        apiKey: rootGetters['auth/apiKey'],
      });

      return data;
    },
  },
};
