import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import { appEvents } from '@/constants/events';
import NewLayout from '@/layouts/NewLayout.vue';
import DealerLayout from '@/layouts/DealerLayout.vue';
import DefaultLayout from '../layouts/DefaultLayout.vue';
// import DealerLayout from '../layouts/DealerLayout.vue';

const DEFAULT_META_TITLE = 'Ford EV Tour';

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView,
  //   meta: {
  //     title: `Home | ${DEFAULT_META_TITLE}`,
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/event/:eventSlug',
  //   name: 'event',
  //   component: () => import(
  //   /* webpackChunkName: "event" */ '../views/EventView.vue'),
  //   meta: {
  //     title: `Event | ${DEFAULT_META_TITLE}`,
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/sweepstakes',
  //   name: 'sweepstakes',
  //   component: () => import(
  //   /* webpackChunkName: "sweepstakes" */ '../views/SweepstakesView.vue'),
  //   meta: {
  //     title: `Sweepstakes | ${DEFAULT_META_TITLE}`,
  //     layout: DefaultLayout,
  //   },
  //   children: [
  //     {
  //       path: ':eventSlug',
  //       component: () => import(
  //       /* webpackChunkName: "sweepstakes" */ '../views/SweepstakesView.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/faqs',
  //   name: 'faqs',
  //   component: () => import(
  //   /* webpackChunkName: "faqs" */ '../views/FaqsView.vue'),
  //   meta: {
  //     title: `Faqs | ${DEFAULT_META_TITLE}`,
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/dealer',
  //   name: 'dealer',
  //   component: () => import(
  //   /* webpackChunkName: "dealer" */ '../views/DealerView.vue'),
  //   meta: {
  //     title: `Dealer | ${DEFAULT_META_TITLE}`,
  //     layout: DealerLayout,
  //     requiresAuth: true,
  //   },
  //   beforeEnter: () => {
  //     if (!store.getters['dealer/hasDealerViewAuthorization']) {
  //       const toast = useToast();
  //       toast.error('Unable to access');

  //       return { name: 'home' };
  //     }

  //     return true;
  //   },
  // },
  // {
  //   path: '/dealer/login',
  //   name: 'login',
  //   component: () => import(
  //     /* webpackChunkName: "dealer-login" */ '../views/DealerLoginView.vue'),
  //   meta: {
  //     title: `Dealer Login | ${DEFAULT_META_TITLE}`,
  //     layout: DealerLayout,
  //   },
  // },
  {
    path: '/event-registration',
    name: 'event-registration',
    component: () => import(/* webpackChunkName: "event-registration" */ '../views/EventRegistrationView.vue'),
    meta: {
      title: `Event Registration | ${DEFAULT_META_TITLE}`,
      layout: NewLayout,
    },
  },
  {
    path: '/test-drive-registration',
    name: 'test-drive-registration',
    component: () => import(/* webpackChunkName: "test-drive-registration" */ '../views/TestDriveRegistrationView.vue'),
    meta: {
      title: `Registration | ${DEFAULT_META_TITLE}`,
      layout: NewLayout,
    },
  },
  {
    path: '/test-drive-survey',
    name: 'test-drive-survey',
    component: () => import(/* webpackChunkName: "test-drive-survey" */ '../views/TestDriveSurveyView.vue'),
    meta: {
      title: `Survey | ${DEFAULT_META_TITLE}`,
      layout: NewLayout,
    },
  },
  {
    path: '/1400-raffle',
    name: 'raffle',
    component: () => import(/* webpackChunkName: "raffle" */ '../views/RaffleView.vue'),
    meta: {
      title: `1400 Raffle | ${DEFAULT_META_TITLE}`,
      layout: DealerLayout,
    },
  },
  {
    path: '/communication-preferences/:userId',
    name: 'communication-preferences',
    component: () => import(/* webpackChunkName: "communication-preferences" */ '../views/CommunicationPreferencesView.vue'),
    meta: {
      title: `Communication Preferences | ${DEFAULT_META_TITLE}`,
      layout: DefaultLayout,
      disablePageSharing: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
});

router.beforeEach(async (to) => {
  if (to.meta.requiresAuth && !store.getters['auth/userIsAuthenticated']) {
    return { name: 'login' };
  }

  return true;
});

router.afterEach(async (to, from) => {
  document.title = to.meta.title || DEFAULT_META_TITLE;

  if (to.name === from.name) return;

  const path = to.path.replace('/', '');

  store.dispatch('appInsights/trackEvent', {
    name: `${appEvents.ACCESS_PAGE} ${path || to.name}`,
  });
});

export default router;
