import api from '@/api';

export default {
  namespaced: true,
  state() {
    return {
      authToken: null,
      apiKey: null,
      campaignId: '',
    };
  },
  getters: {
    userIsAuthenticated: ({ authToken }) => !!authToken,
    apiKey: (state) => state.apiKey,
    campaignId: (state) => state.campaignId,
  },
  mutations: {
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setApiKey(state, apiKey) {
      state.apiKey = apiKey;
    },
    setCampaignId(state, campaignId) {
      state.campaignId = campaignId;
    },
  },
  actions: {
    setAuthToken({ commit }, token) {
      commit('setAuthToken', token);
    },
    setCampaignId({ commit }, campaignId) {
      commit('setCampaignId', campaignId);
    },
    async validateKey(_, key) {
      const {
        data: { isAdmin, isAuthenticated: keyIsValid },
      } = await api.authService.validateKey(key);

      return { isAdmin, keyIsValid };
    },
  },
};
