export default {
  namespaced: true,
  state() {
    return {
      firstName: '',
      lastName: '',
      paCode: '',
      event: {
        eventName: '',
        eventSlug: '',
        paCode: '',
        eventStyle: '',
        eventStartDate: '',
        pk_Program_EventID: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        interceptVenue: '',
        eventTimezone: '',
        eventEndDate: '',
        activationLocation: '',
        locationType: '',
        eventSpace: '',
        testDrive: '',
        blueCruiseAvailable: '',
        panelDiscussion: '',
        media: '',
        socialMedia: '',
        zevState: '',
        ticketSite: '',
        siteReady: '',
        eventDescription: '',
      },
    };
  },
  getters: {
    hasDealerViewAuthorization: (state) => !!state.event.pk_Program_EventID,
    paCode: (state) => state.paCode,
    event: (state) => state.event,
  },
  mutations: {
    setDealerDetails(state, {
      firstName,
      lastName,
      paCode,
      event,
    }) {
      state.firstName = firstName;
      state.lastName = lastName;
      state.paCode = paCode;
      state.event = event;
    },
  },
  actions: {
    saveDealerDetails({ commit }, data) {
      commit('setDealerDetails', data);
    },
  },
};
