<template>
  <footer
    class="
      h-[18.75rem]
      bg-footer-mobile bg-no-repeat bg-bottom bg-contain
      tablet:bg-footer tablet:bg-cover
    "
  >
    <div class="container h-full flex items-center tablet:items-end">
      <div
        class="
          flex flex-col
          items-center
          tablet:flex-row tablet:justify-between
          h-[13.125rem]
          w-full
        "
      >
        <router-link to="/" class="mb-7">
          <AppBrandLogo width="146" height="58" class="w-[clamp(6.625rem,_10vw,_9.125rem)]" />
        </router-link>
        <nav
          class="
            font-FordAntennaCondMed
            text-xs-fluid
            child:underline
            child-hover:text-accent-color
            child:transition-colors child:duration-300 child:ease-in-out
            space-y-4
            flex flex-col
            items-center
            tablet:flex-row tablet:space-x-11 tablet:space-y-0
          "
        >
          <BaseButton
            :to="{ name: 'faqs' }"
            @click="trackEvent(footerEvents.FAQS_CLICK)"
          >
            FAQs
          </BaseButton>
          <BaseButton
            :to="{ name: 'dealer' }"
            @click="trackEvent(footerEvents.DEALER_ACCESS_CLICK)"
          >
            Dealer Access
          </BaseButton>
          <BaseButton
            to="https://www.ford.com/help/privacy/"
            @click="trackEvent(footerEvents.PRIVACY_CLICK)"
          >
            Privacy Statement
          </BaseButton>
          <BaseButton
            to="https://www.ford.com/help/privacy/#caPrivacy"
            @click="trackEvent(footerEvents.CA_PRIVACY_CLICK)"
          >
            CA Privacy Statement
          </BaseButton>
        </nav>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { useStore } from 'vuex';
import { footerEvents } from '@/constants/events';
import AppBrandLogo from '../AppBrandLogo.vue';
import BaseButton from '../BaseButton.vue';

const store = useStore();

// Track events
function trackEvent(eventName) {
  store.dispatch('appInsights/trackEvent', {
    name: eventName,
  });
}
</script>
