import { createStore } from 'vuex';
import appInsightsModule from './modules/appInsights';
import authModule from './modules/auth';
import eventsModule from './modules/events';
import sweepstakesModule from './modules/sweepstakes';
import dealerModule from './modules/dealer';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    appInsights: appInsightsModule,
    auth: authModule,
    events: eventsModule,
    sweepstakes: sweepstakesModule,
    dealer: dealerModule,
  },
});
