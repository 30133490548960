const eventsStore = {
  namespaced: true,
  state: {
    events: [],
  },
  mutations: {
    setEvents: (state, events) => { state.events = events; },
    deleteEvents: (state) => { state.events = []; },
  },
  actions: {
    deleteAllEvents({ dispatch }) {
      dispatch('deleteEvents');
    },
    saveEvents({ commit }, events) {
      if (!events.length > 0) return;
      commit('setEvents', events);
    },
  },
  getters: {
    allEvents: (state) => state.events,
    eventByID: (state) => (id) => state.events
      .find((event) => event.fk_Program_EventID === id),
  },
};

export default eventsStore;
