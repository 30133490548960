import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import './tailwind.css';

const head = createHead();

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(head)
  .use(store)
  .use(router)
  .use(Toast, {
    containerClassName: 'custom-container-class',
    bodyClassName: 'toast-custom-body-class',
  })
  .mount('#app');
