<template>
  <BaseButton
    class="
      inline-block
      border-2
      py-[0.875rem]
      px-[1.5rem]
      rounded
      text-center
      uppercase
      outline-offset-2
      transition-colors
      hover:data-[color=primary]:border-super-dark-blue
      hover:data-[color=primary]:text-super-dark-blue
      data-[color=primary]:border-ford-blue
      data-[color=primary]:text-ford-blue
      data-[color=primary]:hover:bg-white
      data-[color=primary]:hover:text-black
      data-[color=filled-primary]:bg-ford-blue
      data-[color=filled-primary]:text-white
      data-[color=filled-primary]:hover:bg-[#1700f4]
      data-[color=secondary]:border-accent-color
      data-[color=secondary]:text-accent-color
      data-[color=secondary]:hover:bg-accent-color
      data-[color=secondary]:hover:text-black
      data-[color=filled-secondary]:bg-accent-color
      data-[color=filled-secondary]:text-white
      data-[color=filled-secondary]:hover:bg-accent-color
      data-[color=filled-secondary]:hover:text-black
    "
    :data-color="color"
    :data-type="type"
    :disabled="buttonIsDisabled"
    :to="to"
    @click="$emit('click')"
  >
    <font-awesome-icon
      v-show="isLoading"
      class="fa-spin text-[1.25rem]"
      style="--fa-animation-duration: 0.75s"
      :icon="faSpinnerThird"
    />
    <template v-if="!isLoading">
      <slot />
    </template>
  </BaseButton>
</template>

<script setup>
import { computed } from 'vue';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import BaseButton from './BaseButton.vue';

defineEmits(['click']);

const props = defineProps({
  to: {
    type: [String, Object],
    default: '',
  },
  color: {
    type: String,
    default: 'primary',
    validator: (color) => Object.values(['primary', 'secondary', 'filled-primary', 'filled-secondary']).includes(color),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const buttonIsDisabled = computed(() => props.isLoading || props.disabled);
</script>
