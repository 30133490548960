<template>
  <component :is="route.meta.layout" />
</template>

<script setup>
import { useHead } from '@vueuse/head';
import { computed, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { appEvents } from '@/constants/events';

const store = useStore();
const router = useRouter();
const route = useRoute();

const siteData = reactive({
  description: 'COME DRIVE AND EXPERIENCE MUSTANG MACH-E® AND F-150® LIGHTNING®',
});
useHead({
  meta: [
    {
      name: 'description',
      content: computed(() => siteData.description),
    },
  ],
});

// Capture campaignId
onMounted(async () => {
  await router.isReady();

  const { campaign_id: campaignId } = route.query;

  if (campaignId) {
    store.dispatch('auth/setCampaignId', campaignId);
    store.dispatch('appInsights/trackEvent', {
      name: `${appEvents.CAMPAIGN_ID} ${campaignId}`,
    });
  }
});
</script>
