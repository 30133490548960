import { baseCRUDAPI } from './base';

export default class AuthService extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'auth');
  }

  validateKey(key) {
    return this.axiosObject.post(`${this.apiBaseName}/validate-api-key`, null, {
      params: { key },
    });
  }

  getLoginURL() {
    return this.axiosObject.get(`${this.apiBaseName}/ford/login-url`);
  }

  validateToken(authCode) {
    return this.axiosObject.post(`${this.apiBaseName}/ford/token`, { authCode });
  }
}
