export const appEvents = {
  CAMPAIGN_ID: 'User visited the site with campaign_id',
  ACCESS_PAGE: 'Access page',
};

export const loginEvents = {
  COMPLETE_LOGIN: 'Login process was completed with PA Code',
};

export const dealerEvents = {
  CLICK_EVENT_LINK: 'Click Dealer page button Event Link',
  CLICK_READINESS_CHECKLIST: 'Click Dealer page button Readiness Checklist',
  CLICK_BEST_PRACTICES: 'Click Dealer page button Best Practices',
  CLICK_EMAIL_TEMPLATES: 'Click Dealer page button Email Templates',
  CLICK_MEDIA_TEMPLATES: 'Click Dealer page button Social Media Templates',
};

export const menuEvents = {
  SHARE_CLICK: 'Click menu button Share',
  EVENTS_LINK_CLICK: 'Click menu item Events',
  EXPLORE_VEHICLES_LINK_CLICK: 'Click menu item Explore Electric Vehicles',
  SWEEPSTAKES_LINK_CLICK: 'Click menu item Mustang Mach-E Sweepstakes',
};

export const footerEvents = {
  FAQS_CLICK: 'Click footer item FAQs',
  DEALER_ACCESS_CLICK: 'Click footer item Dealer Access',
  PRIVACY_CLICK: 'Click footer item Privacy Statement',
  CA_PRIVACY_CLICK: 'Click footer item CA Privacy Statement',
};

export const sweepstakesBannerEvents = {
  OFFICIAL_RULES_CLICK: 'Click sweepstakes banner link Official Rules',
  ENTER_HERE_CLICK: 'Click sweepstakes banner button Enter Here',
};

export const homeEvents = {
  EVENT_MAP_SEARCH: 'Perform a search on the Home page Event Map.',
  EVENT_MAP_NAVIGATION: 'Navigate from Home page Event Map to event',
  MUSTANG_MACH_E_BUTTON_CLICK: 'Click Home page button Mustang Mach-e',
  F150_LIGHTNING_BUTTON_CLICK: 'Click Home page button F150 Lightning',
  NAVIGATION_SECTION_EVENT_MAP: 'Navigate to the section Event Map',
  NAVIGATION_SECTION_EXPECTATIONS: 'Navigate to the section What to Expect',
  NAVIGATION_SECTION_EXPLORE_VEHCLES: 'Navigate to the section Explore Electric Vehicles',
  NAVIGATION_SECTION_SWEEPSTAKES: 'Navigate to the section Mustang Mach-e Sweepstakes',
  NAVIGATION_FOOTER: 'Navigate to the footer',
  VIDEO_PLAYED: 'Start playing the What To Expect video',
};

export const eventPageEvents = {
  ADDRESS_LINK_CLICK: 'Click Event page link Address',
  PHONE_NUMBER_LINK_CLICK: 'Click Event page link Phone Number',
  ADD_TO_CALENDAR_CLICK: 'Click Event page button Add To Calendar',
  EXPO_BUTTON_CLICK: 'Click Event page button Electrify Expo Tickets',
};

export const faqEvents = {
  VIEW_QUESTION: 'View FAQs question',
  CONTACT_US_CLICK: 'Click FAQs Contact Us',
};

export const sweepstakesEvents = {
  SUBMIT_BUTTON_CLICK: 'Click Sweepstakes page button Enter Here',
  RULES_LINK_CLICK: 'Click Sweepstakes page link Rules',
  PRIVACY_POLICY_LINK_CLICK: 'Click Sweepstakes page link Ford\'s Privacy Policy',
};
