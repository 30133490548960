import { baseCRUDAPI } from './base';

export default class EventsService extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'event');
  }

  async getAll() {
    return this.axiosObject.get(`${this.apiBaseName}`);
  }

  getEventBySlug(eventSlug) {
    return this.axiosObject.get(`${this.apiBaseName}/slug`, {
      params: {
        slug: eventSlug,
      },
    });
  }
}
