<template>
  <header
    class="text-sm-fluid tracking-widest"
    :class="isNew ? 'bg-transparent' : 'bg-black tablet:bg-transparent'">
    <div class="container h-full" :class="isNew ? 'items-center' : ''">
    <template v-if="!isNew">
      <router-link to="/" class="self-center">
        <AppBrandLogo class="py-4 w-[clamp(4.625rem,_8.33vw,_9rem)]" />
      </router-link>
    </template>
    <template v-else>
      <AppBrandLogo blueLogo class="py-4 w-[clamp(4.625rem,_8.33vw,_9rem)] mx-auto" />
    </template>
      <div
        :class="props.isNew
          ? 'border-t border-[#00245B]'
          : 'hidden h-12 border-y tablet:block border-white'">
      </div>
    </div>
  </header>
</template>

<script setup>
import { defineProps } from 'vue';
import AppBrandLogo from '../AppBrandLogo.vue';

const props = defineProps({
  isNew: { type: Boolean, required: false },
});
console.log('Props NEW ::: ', props.isNew);
</script>
