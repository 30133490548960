<template>
  <header id="header" class="text-sm-fluid tracking-widest bg-black tablet:bg-transparent">
    <div
      class="
        relative
        flex
        justify-between
        h-[clamp(4.625rem,_6.75vw,_7.5rem)]
        bg-black
        z-50
        tablet:bg-transparent"
    >
      <router-link to="/" @click="closeNavDrawer()" class="self-center">
        <AppBrandLogo width="144" height="57" class="w-[clamp(4.625rem,_8.33vw,_9rem)]" />
      </router-link>
      <button @click="toggleNavDrawer" class="w-8 h-8 pt-1 text-2xl self-center tablet:hidden">
        <span v-if="!navDrawerOpen" class="sr-only">Open main menu</span>
        <span v-else class="sr-only">Close main menu</span>
        <TransitionScaleInOutCenter>
          <font-awesome-icon v-if="!navDrawerOpen" key="openMenu" :icon="faBars" />
          <font-awesome-icon v-else key="closeMenu" :icon="faTimes" />
        </TransitionScaleInOutCenter>
      </button>
      <button
        v-if="!$route.meta.disablePageSharing"
        class="
          self-end
          uppercase
          leading-none
          hidden
          tablet:block
          mb-[clamp(0.625rem,_1vw,_1.25rem)]"
        @click="handleShareClick"
      >
        Share<font-awesome-icon :icon="faArrowUpRightFromSquare" class="ml-2.5" />
      </button>
    </div>
    <nav
      class="relative
      h-auto
      uppercase
      text-center
      space-y-5
      bg-black
      tablet:bg-transparent
      tablet:py-2
      tablet:space-y-0
      tablet:space-x-12
      tablet:flex
      tablet:items-center
      tablet:justify-end
      tablet:border-y
      tablet:border-white z-40"
    >
      <router-link
        :to="{ path: '/', hash: '#events' }"
        @click="closeNavDrawer(), trackEvent(menuEvents.EVENTS_LINK_CLICK)"
        class="py-2.5 block border-2 border-white rounded tablet:border-none tablet:rounded-none"
        >Events</router-link
      >
      <router-link
        :to="{ path: '/', hash: '#electric-vehicles' }"
        class="py-2.5 hidden tablet:block"
        @click="trackEvent(menuEvents.EXPLORE_VEHICLES_LINK_CLICK)"
        >Explore Electric Vehicles</router-link
      >
      <AppButton
        class="py-[0.5rem] hidden tablet:block"
        :to="{ name: 'sweepstakes' }"
        @click="trackEvent(menuEvents.SWEEPSTAKES_LINK_CLICK)"
      >
        Ford EV Tour Sweepstakes
      </AppButton>
    </nav>
    <nav
      :class="navDrawerOpen ? 'top-[7.5rem]' : '-top-full'"
      class="absolute
      left-0
      right-0
      uppercase
      text-center
      space-y-5
      py-5
      child:block
      bg-black
      transition-top
      duration-300
      ease-in-out
      z-30
      tablet:hidden"
    >
      <router-link
        :to="{ path: '/', hash: '#electric-vehicles' }"
        @click="closeNavDrawer()"
        class="py-2.5"
        >Explore Electric Vehicles</router-link
      >
      <router-link to="/sweepstakes" @click="closeNavDrawer()" class="py-2.5"
        >Ford EV Tour Sweepstakes</router-link
      >
    </nav>
  </header>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { Clipboard } from 'v-clipboard';
import { useToast } from 'vue-toastification';
import {
  faBars,
  faTimes,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { menuEvents } from '@/constants/events';
import AppButton from '../AppButton.vue';
import AppBrandLogo from '../AppBrandLogo.vue';
import TransitionScaleInOutCenter from '../TransitionScaleInOutCenter.vue';

const store = useStore();
const toast = useToast();

const navDrawerOpen = ref(false);

const toggleBodyClass = () => {
  const el = document.body;
  if (navDrawerOpen.value === true) {
    el.classList.add('overflow-hidden');
  } else {
    el.classList.remove('overflow-hidden');
  }
};

const toggleNavDrawer = () => {
  navDrawerOpen.value = !navDrawerOpen.value;
  toggleBodyClass();
};

const closeNavDrawer = () => {
  navDrawerOpen.value = false;
  toggleBodyClass();
};

// Track events
function trackEvent(eventName) {
  store.dispatch('appInsights/trackEvent', {
    name: eventName,
  });
}

function handleShareClick() {
  Clipboard.copy(window.location.href.split('#')[0]);

  trackEvent(menuEvents.SHARE_CLICK);

  toast.success('Link Copied!', {
    timeout: 10000,
  });
}
</script>
