<template>
  <transition name="fade">
    <button
      class="
        fixed
        bottom-20
        -right-5
        font-FordAntennaCondMed
        text-base
        tracking-widest
        leading-none
        px-2.5
        scroll-to-top
        -rotate-90
        hidden
        items-center
        desktop-xxl:flex
      "
      v-show="showButton"
      @click="scrollToTop"
    >
      <span class="-mt-1">Back to Top</span>
      <span class="text-2xl text-accent-color ml-2.5">
        <font-awesome-icon :icon="faChevronRight" class="-mr-1" />
        <font-awesome-icon :icon="faChevronRight" class="-mr-1" />
        <font-awesome-icon :icon="faChevronRight" />
      </span>
    </button>
  </transition>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

const showButton = ref(false);

const handleScroll = () => {
  showButton.value = window.scrollY > 200;
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
